import * as types from './actionTypes';
import getUserData from '../../data/getUserData';
import getGiftList from '../../data/getGiftListForUser';
import getGiftee from '../../data/getGifteeForUser';
import { toastError } from '../../helpers/toastHelpers';
import { removeCurrentUser } from '../../helpers/authHelpers';

export const getUserDetails = (user, login = false) => (dispatch) => {
  if (user.userId) {
    getUserData(user.userId, login)
      .then(response => {
        if (response.exists) {
          dispatch({
            type: types.USER_INFORMATION_UPDATE, payload: {
              ...user,
              ...response,
            },
          });

          // If there is an exhcangeId get the giftee user information
          response.exchangeId &&
            getGiftee(user.userId, response.exchangeId)
              .then(response => {
                if (response && !response.error && response.found) {
                  getUserData(response.giftee)
                    .then((gifteeResponse) => {
                      if (gifteeResponse.exists) {
                        const gifteeUserId = gifteeResponse.userId;

                        dispatch({
                          type: types.USER_GIFTEE,
                          payload: {
                            firstName: gifteeResponse.firstName,
                            lastName: gifteeResponse.lastName,
                            displayName: gifteeResponse.displayName,
                            email: gifteeResponse.email,
                          },
                        });

                        getGiftList(gifteeUserId)
                          .then(gifteeGiftListResponse => {
                            dispatch({
                              type: types.USER_GIFTEE_GIFT_LIST,
                              payload: {
                                giftList: gifteeGiftListResponse.giftList || [],
                                lastUpdated: gifteeGiftListResponse.lastUpdated || null,
                              },
                            });
                          });
                      }
                    });
                }
              });
        } else {
          toastError('How embarassing, we don\'t have your info... Can you please Register?');
          removeCurrentUser();
          dispatch({ type: types.RESET_STATE });
        }
      })
      .catch(error => {
        console.log(error);
        toastError(error.message);
      });
  }
};

export const getUserGiftee = (userId, exchangeId) => (dispatch) => {
  getGiftee(userId, exchangeId)
    .then(response => {
      dispatch({
        type: types.USER_GIFTEE,
        payload: (!response.error && response.found) ? response.giftee : null,
      });
    });
};

export const getUserGiftList = (userId, type = types.USER_GIFT_LIST) => (dispatch) => {
  getGiftList(userId)
    .then(response => {
      dispatch({
        type: type,
        payload: {
          exchangeId: response.exchangeId || null,
          giftList: response.giftList || [],
          lastUpdated: response.lastUpdated || null,
        },
      });
    });
};
