import firebase from '../firebase';

const setUser = async (userId, data) => {
  const setUserData = await firebase.firestore().collection('users').doc(userId).set(data)
    .then(() => ({ success: true, message: 'Successfully Updated!' }))
    .catch((error) => ({ success: false, message: `Error Saving: ${error}` }));
  return setUserData;
};

export default setUser;
