import React, { useState } from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit as editIcon,
  faSave as saveIcon,
  faMinusCircle as deleteIcon,
  faUndoAlt as cancelIcon,
} from '@fortawesome/free-solid-svg-icons';
import { isUrlValid, urlAmazonModifier } from '../../helpers/urlHelpers';
import './giftList.scss';

const GiftItem = props => {
  const [editDescription, setDescription] = useState(props.gift.description);
  const [editHelper, setHelper] = useState(props.gift.helperLink);
  const [errors, setErrors] = useState(null);

  const validateHelper = (url) => {
    if (!isUrlValid(url)) {
      setErrors({ helper: 'Please provide a valid link' });
    } else {
      setErrors(null);
    }

    setHelper(url);
  };

  const helperLinkMatch = props.gift.helperLink.match(/\bhttps?:\/\/\S+/gi) || [];
  const extraHelperLinkText = props.gift.helperLink.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
  return (
    <div className="my-3 gift-item">
      <div className="information">

        <div className="description">
          {
            props.edit && props.gift.edit
              ? (
                <input
                  type="text"
                  className="form-control"
                  id="editDesctiption"
                  name="editDesctiption"
                  onChange={(e) => setDescription(e.target.value)}
                  value={editDescription}
                />
              )
              : <span>{props.gift.description}</span>
          }
        </div>
        <div className="helper">
          {
            props.edit && props.gift.edit
              ? (
                <>
                  <input
                    type="url"
                    className={`form-control${errors && errors.helper ? ' is-invalid' : ''}`}
                    id="editHelper"
                    name="editHelper"
                    onChange={(e) => validateHelper(e.target.value)}
                    value={editHelper}
                  />
                  {
                    errors?.helper && (
                      <div id="editHelper" class="invalid-feedback">
                        {errors.helper}
                      </div>
                    )
                  }
                </>
              )
              : (
                <span className={props.gift.helperLink ? 'normal' : 'italic'}>
                  {helperLinkMatch.length > 0
                    ? (
                      <div>
                        <span>{extraHelperLinkText}</span>
                        <ReactTinyLink
                          cardSize="small"
                          showGraphic={true}
                          maxLine={4}
                          minLine={2}
                          onError={error => console.log("ERROR: ", error, 'LINK: ', props.gift.helperLink)}
                          url={helperLinkMatch[0]}
                        />
                      </div>
                    )
                    : 'No helper link'}
                </span>
              )
          }
        </div>

      </div>
      {
        props.edit && (

          <div className="edit-options">
            <button
              type="button"
              className={`button top ${errors ? 'disabled' : 'green'}`}
              disabled={!!errors}
              onClick={() => {
                if (!props.gift.edit) {
                  props.handleEditToggle();
                } else {
                  const modifiedHelper = urlAmazonModifier(editHelper);
                  props.handleSave({
                    description: editDescription,
                    helperLink: modifiedHelper,
                  });
                  setHelper(modifiedHelper);
                }
              }}
            >
              {
                !props.gift.edit
                  ? <FontAwesomeIcon icon={editIcon} sz="sm" />
                  : <FontAwesomeIcon icon={saveIcon} sz="sm" />
              }
            </button>
            {props.gift.edit && <button type="button" onClick={() => {
              props.handleEditToggle();
              setDescription(props.gift.description);
              setHelper(props.gift.helperLink);
            }}><FontAwesomeIcon icon={cancelIcon} sz="sm" /></button>}
            <button
              type="button"
              className="button bottom red"
              onClick={props.handleDelete}
            ><FontAwesomeIcon icon={deleteIcon} sz="sm" /></button>
          </div>

        )
      }
    </div>
  );
};

export default GiftItem;