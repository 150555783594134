import React from 'react';
import version from '../../version.json';
import './version.scss';

const Version = () => (
  <div id="version-container">
    <span className="m-2">
      {version.version}
    </span>
  </div>
);

export default Version;