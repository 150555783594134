import initialState from './initialState';
import { USER_INFORMATION_UPDATE, RESET_STATE } from '../actions/actionTypes';

const userReducer = (state = initialState.user, action) => {
  if (action.type === USER_INFORMATION_UPDATE) {
    return action.payload;
  } else if (action.type === RESET_STATE) {
    return initialState.user;
  }
  return state;
};

export default userReducer;