import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt as logoutIcon,
  faGifts as giftIcon,
} from '@fortawesome/free-solid-svg-icons';
import { userLogout } from '../../helpers/authHelpers';
import santaHat from '../../images/santa-hat-1.png';
import giftBox from '../../images/gift-red-bow-1-1.png';
import './nav.scss';

const Nav = (props) => {
  const path = props.location.pathname;
  return (
    <nav id="gift-navbar" className="navbar navbar-expand-md navbar-light">
      <a className="navbar-brand align-self-stretch" href="/">
        <img src={giftBox} className="d-inline-block align-top" alt="" />
      </a>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#giftNavDropDown" aria-controls="giftNavDropDown" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse justify-content-between" id="giftNavDropDown">

        <ul className="navbar-nav">
          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-link nav-link ${path === '/' ? 'active' : ''}`}
              onClick={() => {
                props.history.push('/');
              }}
            >Home
            </button>
          </li>

          {
            // Menu elements for users that are authenticated
            props.user.userId
              ? (
                <>
                  <li className="nav-item">
                    <button
                      type="button"
                      className={`btn btn-link nav-link ${path === '/gifts' ? 'active' : ''}`}
                      onClick={() => {
                        $('.navbar-collapse').collapse('hide');
                        props.history.push('/gifts');
                      }}
                    >My Gift List
                    </button>
                  </li>

                  <li className="nav-item">
                    <button
                      type="button"
                      className={`btn btn-link nav-link${path === '/gifts/giftee' ? ' active' : ''}`}
                      onClick={() => {
                        $('.navbar-collapse').collapse('hide');
                        props.history.push('/gifts/giftee');
                      }}
                    >Giftee Gift List {moment(props.user.lastLogin).isBefore(props.gifteeGiftListUpdated) && <FontAwesomeIcon className="pulse" icon={giftIcon} />}
                    </button>
                  </li>
                </>
              )
              : (
                // Menu items that disappear once authenticated
                <li className="nav-item">
                  <button
                    type="button"
                    className={`btn btn-link nav-link ${path === '/register' ? 'active' : ''}`}
                    onClick={() => {
                      props.history.push('/register');
                    }}
                  >Register
                  </button>
                </li>
              )
          }
        </ul>

        {
          // Login button logic
          props.user.userId
            ? (
              <div id="gift-user" className="dropdown">
                <button
                  id="userButton"
                  type="button"
                  className="btn btn-gift-green btn-sm my-2 my-sm-0 align-self-end"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img id="loginIcon" src={(props.user.photoUrl && props.user.photoUrl !== '') ? props.user.photoUrl : santaHat} alt="User Avatar" />
                  {props.user.displayName || props.user.firstName || props.user.email}
                </button>
                <div className="dropdown-menu dropdown-menu-right" style={{ minWidth: 225 }}>

                  <div id="last-login-container" style={{ fontSize: '0.8rem', padding: '0.25rem 1.5rem' }}>
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Last Login:</span>
                    <span className="ml-2" style={{ fontWeight: 300 }}>{moment(props.user.lastLogin).format('lll')}</span>
                  </div>
                  <div className="dropdown-divider" />

                  {
                    !props.user.facebookUser && (
                      <div id="non-provider-menu-items">
                        <button
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#updatePasswordModal"
                          type="button"
                        >Update Password
                        </button>
                        <div className="dropdown-divider" />
                      </div>
                    )
                  }

                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={userLogout}>
                    <FontAwesomeIcon icon={logoutIcon} /> Logout
                  </button>
                </div>
              </div>
            )
            : (
              <div id="gift-login">
                <button
                  id="loginButton"
                  type="button"
                  className="btn btn-gift-red btn-sm my-2 my-sm-0 align-self-end"
                  data-toggle="modal"
                  data-target="#loginModal"
                >
                  Login <img id="loginIcon" src={santaHat} alt="Login" />
                </button>
              </div>
            )}
      </div>
    </nav>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  gifteeGiftListUpdated: (state.gifteeGiftList && state.gifteeGiftList.lastUpdated) ? state.gifteeGiftList.lastUpdated : null,
});

export default connect(mapStateToProps)(withRouter(Nav));
