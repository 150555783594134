import { combineReducers } from 'redux';
import user from './userReducer';
import userGiftList from './userGiftListReducer';
import gifteeGiftList from './userGifteeGiftListReducer';
import giftee from './userGifteeReducer';

export default combineReducers({
  user,
  userGiftList,
  gifteeGiftList,
  giftee,
});
