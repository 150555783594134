import React from 'react';
import { withRouter } from 'react-router-dom';

// import FacebookLogin from '../../components/FacebookLogin';
import { createUserWithEmail } from '../../helpers/authHelpers';
import { firebaseUserObjectTranslation } from '../../helpers/userHelpers';
import setUserData from '../../data/setUserData';
import { toastSuccess, toastError } from '../../helpers/toastHelpers';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.facebookRegister = this.facebookRegister.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.email && this.state.password && this.state.firstName && this.state.lastName) {
      createUserWithEmail(this.state.email, this.state.password)
        .then(({ user }) => {
          const newUser = {
            ...firebaseUserObjectTranslation(user),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            displayName: `${this.state.firstName} ${this.state.lastName}`,
            lastLogin: Date().toLocaleString(),
          };

          // write user data to the 'users' collection
          setUserData(newUser.userId, newUser)
            .then(() => {
              toastSuccess('User Successfully Created');
              this.props.history.push('/');
            })
            .catch(error => {
              toastSuccess('User Successfully Created');
              toastError(error.message);
              this.props.history.push('/');
            });
        })
        .catch(error => this.setState({ error: null }, () => toastError(error.message)));
    } else {
      this.setState({ error: 'Email, Password, as well as First & Last Names are all mandatory' });
    }
  }

  facebookRegister(facebookUser) {
    const newUser = {
      ...firebaseUserObjectTranslation(facebookUser),
      firstName: '',
      lastName: '',
      facebookUser: true,
      lastLogin: Date().toLocaleString(),
    };

    // write user data to the 'users' collection
    setUserData(newUser.userId, newUser)
      .then(() => {
        toastSuccess('User Successfully Created');
        this.props.history.push('/');
      })
      .catch(error => {
        toastSuccess('User Successfully Created');
        toastError(error.message);
        this.props.history.push('/');
      });
  }

  render() {
    return (
      <div id="gift-signup-container">
        <h1 className="mb-4">Sign Up</h1>

        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-sm-12 col-md-6">
              <FacebookLogin responseHandler={this.facebookRegister} buttonText="Sign Up with Facebook" />

              <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <hr className="dark-red" style={{ width: '40%' }} />
                <span className="text-dark-red" style={{ fontSize: '1.5rem', fontWeight: 200 }}>OR</span>
                <hr className="dark-red" style={{ width: '40%' }} />
              </div>

            </div>
          </div> */}
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <form>
                <fieldset>

                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" placeholder="Your email" onChange={this.handleChange} value={this.state.email} required />
                    <small id="emailHelp" className="form-text text-mute">It will be your username to login</small>
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" name="password" aria-describedby="passwordHelp" placeholder="Your password" onChange={this.handleChange} value={this.state.password} required />
                  </div>

                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input type="text" className="form-control" id="firstName" name="firstName" aria-describedby="firstNameHelp" placeholder="Your First Name" onChange={this.handleChange} value={this.state.firstName} required />
                    <small id="nameHelp" className="form-text text-mute">Please use your real name to make life easier</small>
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input type="text" className="form-control" id="lastName" name="lastName" aria-describedby="lastNameHelp" placeholder="Your Last Name" onChange={this.handleChange} value={this.state.lastName} required />
                    <small id="nameHelp" className="form-text text-mute">How about we use our last name's just in case</small>
                  </div>

                  <button type="submit" className="btn btn-lg btn-success mt-3" onClick={this.handleSubmit}>Sign Up</button>
                  {this.state.error ? <small id="errorMessage" className="d-block alert alert-danger mt-3">{this.state.error}</small> : ''}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(Register);