import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyB2It9rxALCR9qmRUPGH_qhfjmYyWpIJrQ',
  authDomain: 'gift-exchange-eaf68.firebaseapp.com',
  databaseURL: 'https://gift-exchange-eaf68.firebaseio.com',
  projectId: 'gift-exchange-eaf68',
  storageBucket: '*.appspot.com',
  messagingSenderId: '142949084244',
  timestampsInSnapshots: true,
};

firebase.initializeApp(config);
export default firebase;
