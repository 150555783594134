import firebase from '../firebase';
import updateUser from './setUserData';

export const getUser = () => firebase.auth();

const getUserData = async (userId, login = false) => {
  const returnUserData = await firebase.firestore().collection('users').doc(userId).get()
    .then((snapshot) => (snapshot.exists ? ({ ...snapshot.data(), exists: true }) : ({ exists: false })))
    .catch((error) => ({ exists: false, error }));

  login && returnUserData.exists && updateUser(userId, {
    ...returnUserData,
    lastLogin: Date().toLocaleString(),
  });

  return returnUserData;
};

export default getUserData;