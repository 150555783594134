import firebase from '../firebase';

const getGiftee = async (userId, exchangeId) => {
  const returnGiftee = await firebase.firestore()
    .collection('exchanges')
    .doc(exchangeId)
    .get()
    .then((snapshot) => {
      const gifteeList = (snapshot.exists ? ({ ...snapshot.data(), found: true, error: false }) : ({ found: false, error: false }));

      return ({
        giftee: gifteeList[userId] || null,
        error: gifteeList.error,
        found: !!(gifteeList[userId]),
      });
    })
    .catch((error) => ({ error: error.message }));
  return returnGiftee;
};

export default getGiftee;