import firebase from '../firebase';

const setGiftList = async (userId, data) => {
  let setGiftList = ({ success: false, message: 'Unknown Error' });
  const newData = {
    ...data,
    lastUpdated: Date().toLocaleString(),
  };
  try {
    setGiftList = await firebase.firestore().collection('gifts').doc(userId).set(newData)
      .then(() => ({ success: true, message: 'Successfully Updated!' }))
      .catch((error) => ({ success: false, message: `Error Saving: ${error}` }));
  } catch (error) {
    setGiftList = ({ success: false, message: `Error Saving: ${error.message}` });
  }

  return setGiftList;
};

export default setGiftList;
