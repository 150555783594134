import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'popper.js/dist/popper';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.scss';

const container = document.getElementById('root');

// Because TypeScript can't guarantee it isn't going to be null, we need to do a check first
if (!container) throw new Error('Failed to find the root element');

const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
