import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import GiftItem from './GiftItem';
import './giftList.scss';

const GifteeList = props => (
  <div id="gift-gift-list-container">
    <div
      className="mb-4"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <h1>
          Giftee List | {
            props.giftee
              ? <span className="text-green giftee-name" style={{ fontWeight: 200 }}> {`${props.giftee.firstName} ${props.giftee.lastName}`}</span>
              : <span className="text-green italic giftee-name" style={{ fontWeight: 100 }}>None Yet</span>
          }
        </h1>
        <div id="last-updated-container" style={{ fontSize: '0.8rem', marginLeft: 5 }}>
          <span style={{ fontWeight: 'bold' }}>Last Updated:</span>
          <span className="ml-2" style={{ fontWeight: 300 }}>
            {
              props.gifteeGiftList && props.gifteeGiftList.lastUpdated
                ? moment(props.gifteeGiftList.lastUpdated).format('lll')
                : 'never'
            }
          </span>
        </div>
      </div>
    </div>

    <div className="container hide-scrollbar" style={{ height: '100%', overflowY: 'scroll', scrollbars: 'none' }}>

      <div className="row">
        <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          {
            (props.gifteeGiftList && props.gifteeGiftList.giftList && props.gifteeGiftList.giftList.length > 0)
              ? (
                <div id="gift-list" style={{ marginBottom: 150 }}>
                  {
                    props.gifteeGiftList.giftList.map((item, index) => (
                      <GiftItem
                        key={index}
                        gift={JSON.parse(item)}
                        edit={false}
                      />
                    ))
                  }
                </div>
              )
              : (
                <div style={{ marginLeft: 25 }}>
                  <span style={{
                    fontSize: '1.5rem',
                    fontWeight: 300,
                    fontStyle: 'italic',
                  }}>
                    {
                      props.giftee
                        ? `You're ready to buy... tell ${props.giftee.firstName} to get going on their list!`
                        : `You're excited to get that perfect gift... wait you don't have a name yet, how do you know it's perfect?`
                    }
                  </span>
                </div>
              )
          }
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  userId: state.user.userId,
  userGiftList: state.userGiftList,
  giftee: state.giftee,
  gifteeGiftList: state.gifteeGiftList,
});

export default connect(mapStateToProps)(GifteeList);