import initialState from './initialState';
import { USER_GIFTEE, RESET_STATE } from '../actions/actionTypes';

const userGifteeReducer = (state = initialState.giftee, action) => {
  if (action.type === USER_GIFTEE) {
    return action.payload;
  } else if (action.type === RESET_STATE) {
    return initialState.giftee;
  }
  return state;
};

export default userGifteeReducer;