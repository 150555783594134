import firebase from '../firebase';


export const createUserWithEmail = (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

export const signInWithFacebook = () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();

  return firebase.auth().signInWithPopup(facebookProvider);
  // .then(function (result) {
  //   // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //   const token = result.credential.accessToken;
  //   // The signed-in user info.
  //   const user = result.user;
  //   console.log('FACEBOOK LOGIN! ', user, ' TOKEN: ', token);
  //   // ...
  // })
  // .catch(function (error) {
  //   // Handle Errors here.
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   // // The email of the user's account used.
  //   // const email = error.email;
  //   // // The firebase.auth.AuthCredential type that was used.
  //   // const credential = error.credential;

  //   console.log('Facebook error... ', errorMessage, `(${errorCode})`);
  // });

};

export const userLogout = () => firebase.auth().signOut().then(() => { window.location.href = '/'; });

export const updateUserProfile = (newProfile) => {
  const user = firebase.auth().currentUser;
  return user.updateProfile(newProfile);
};

export const userRole = async (userId) => {
  const returnUserRole = await firebase.firestore().collection('roles').doc(userId).get()
    .then((snapshot) => ({ role: snapshot.data().role }))
    .catch(() => ({ role: 'guest' }));
  return returnUserRole;
};

export const forgotPassword = async (email) => firebase.auth().sendPasswordResetEmail(email)
  .then(() => ({ status: 'success', message: 'Email sent successfully' }))
  .catch((error) => ({ status: 'error', message: error }));

export const updatePassword = async (newPassword) => firebase.auth().currentUser.updatePassword(newPassword)
  .then(() => ({ status: 'success', message: 'Password updated successfully' }))
  .catch((error) => ({ status: 'error', message: error.message }));

export const removeCurrentUser = () => {
  const currentUser = firebase.auth().currentUser;
  currentUser.delete();
  // .then(response => console.log('DELETE USER:', response))
  // .catch(error => console.log('DELETE USER ERROR: ', error.message));
};