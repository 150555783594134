import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import { bindActionCreators } from 'redux';

import firebase from './firebase';
import { firebaseUserObjectTranslation } from './helpers/userHelpers';
import { getUserDetails, getUserGiftList } from './store/actions/userActions';


import Nav from './components/Nav';
import Content from './views/Content';
import LoginModal from './components/Modals/Login';
import PasswordUpdateModal from './components/Modals/PasswordUpdate';
import Version from './components/Version';

import 'react-toastify/dist/ReactToastify.min.css';

class App extends React.Component {

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.getUserDetails(firebaseUserObjectTranslation(user));
        this.props.getUserGiftList(user.uid);
      }
    });
  }

  render() {
    return (
      <div
        className="App"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
        }}
      >
        <Nav />

        <Content />

        <LoginModal />
        <PasswordUpdateModal />

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          transition={Slide}
          icon={false}
        />
        <Version />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  getUserDetails: bindActionCreators(getUserDetails, dispatch),
  getUserGiftList: bindActionCreators(getUserGiftList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
