import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';

export const toastSuccess = (message) => toast.success(
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-auto align-self-center m-0 p-0">
        <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{ padding: 0 }} />
      </div>
      <div className="col align-self-center">
        <span>{message}</span>
      </div>
    </div>
  </div>,
);

export const toastError = (message) => toast.error(
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-auto align-self-center m-0 p-0">
        <FontAwesomeIcon icon={faExclamation} size="2x" style={{ padding: 0 }} />
      </div>
      <div className="col align-self-center">
        <span>{message}</span>
      </div>
    </div>
  </div>,
);
