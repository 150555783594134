import initialState from './initialState';
import { USER_GIFTEE_GIFT_LIST, RESET_STATE } from '../actions/actionTypes';

const userGifteeGiftListReducer = (state = initialState.gifteeGiftList, action) => {
  if (action.type === USER_GIFTEE_GIFT_LIST) {
    return action.payload;
  } else if (action.type === RESET_STATE) {
    return initialState.gifteeGiftList;
  }
  return state;
};

export default userGifteeGiftListReducer;