import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import firebase from '../../../firebase';

// import FacebookLogin from '../../FacebookLogin';

import { getUserDetails, getUserGiftList } from '../../../store/actions/userActions';
import { firebaseUserObjectTranslation } from '../../../helpers/userHelpers';
import { toastSuccess, toastError } from '../../../helpers/toastHelpers';
import { forgotPassword } from '../../../helpers/authHelpers';
import './login.scss';

const initialState = {
  username: '',
  password: '',
  error: '',
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleInput = this.handleInput.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.processLogin = this.processLogin.bind(this);
  }

  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitLogin(e) {
    e.preventDefault();
    firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(({ user }) => {
        this.processLogin(user);
        $('#loginModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.setState(initialState);
      })
      .catch((error) => this.setState({ error: error.message }));
  }

  resetPassword(e) {
    e.preventDefault();
    forgotPassword(this.state.username)
      .then((result) => (result.status === 'success' ? toastSuccess('Reset Email Sent Successfully') : toastError('Reset Email Unable to be Sent')));
  }

  processLogin(user) {
    this.props.getUserDetails({
      ...firebaseUserObjectTranslation(user),
      userId: user.uid,
    }, true);

    this.props.getUserGiftList(user.uid);
  }

  render() {
    return (
      <div id="gift-login">
        <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form className="px-4 py-3">
                <div className="modal-header">
                  <h3 className="modal-title" id="loginModalLabel">Login</h3>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* <FacebookLogin
                    responseHandler={(user) => {
                      user.facebookUser = true;
                      this.processLogin(user);

                      $('#loginModal').modal('hide');
                      $('body').removeClass('modal-open');
                      $('.modal-backdrop').remove();
                    }}
                    buttonText="Login with Facebook"
                  />

                  <div className="my-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <hr className="dark-red" style={{ width: '40%' }} />
                    <span className="text-dark-red" style={{ fontSize: '1.5rem', fontWeight: 200 }}>OR</span>
                    <hr className="dark-red" style={{ width: '40%' }} />
                  </div> */}

                  <div className="form-group mx-2">
                    <label htmlFor="inputEmail">
                      Email address
                    </label>
                    <input
                      id="inputEmail"
                      type="text"
                      className="form-control"
                      name="username"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email"
                      onChange={this.handleInput}
                      value={this.state.username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputPassword">
                      Password
                    </label>
                    <input
                      id="inputPassword"
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleInput}
                      value={this.state.password}
                    />
                  </div>
                  <span className="text-danger">{this.state.error}</span>
                </div>
                <div className="modal-footer">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                    <button
                      type="button"
                      onClick={this.resetPassword}
                      className="btn btn-link"
                      disabled={this.state.username === ''}
                      data-dismiss="modal"
                    >Forgot Password?
                    </button>
                    <button
                      type="submit"
                      onClick={this.submitLogin}
                      className="btn btn-primary btn-gift-red-dark"
                    >Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserDetails: bindActionCreators(getUserDetails, dispatch),
  getUserGiftList: bindActionCreators(getUserGiftList, dispatch),
});

export default connect(null, mapDispatchToProps)(Login);
