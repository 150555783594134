import React from 'react';
import chistmasGiftMeme from '../../images/christmas-gift-meme-1.jpg';

const randomPicture = () => {
  const pictures = [
    chistmasGiftMeme,
    'https://media.giphy.com/media/11EjiLDatd0syA/giphy.gif',
    'https://media.giphy.com/media/l1AvyLF0Sdg6wSZZS/giphy.gif',
    'https://media.giphy.com/media/3o7TKSdTlEchvBrw2I/giphy.gif',
    'https://media.giphy.com/media/l0ErCJP0RqZn9Xq6c/giphy.gif',
    'https://media.giphy.com/media/l0ErCh3K9OKnRCKPK/giphy.gif',
    'https://media.giphy.com/media/26mfyCpwghTkKjFGE/giphy.gif',
    'https://media.giphy.com/media/3otPoq5NxDmzAFYebK/giphy.gif',
  ];

  return pictures[Math.floor(Math.random() * pictures.length)];
};

const ImageDisplay = () => (
  <div id="gift-image-slider-container" style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1
        className="text-dark-red"

        style={{
          fontSize: '2.5rem',
          fontWeight: 300,
          margin: '20px 0 40px 0',
          display: 'block',
        }}>
        Welcome to Christmas Gift Exchange
      </h1>
      <img
        src={randomPicture()}
        alt="Christmas Gift Meme"
        style={{
          height: 'auto',
          width: '90%',
          // width: 'auto',
          // maxWidth: '90%',
          border: '5px solid rgb(179, 0, 12)',
          borderRadius: 15,
        }}
      />
    </div>
  </div>
);

export default ImageDisplay;