import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import Register from '../../views/Register';
import GiftList from '../../views/GiftList';
import GifteeList from '../../views/GiftList/GifteeList';
// import ComingSoon from '../../components/ComingSoon';
import ImageDisplay from '../../components/ImageDisplay';


const Content = (props) => (
  <section
    id="main-container"
    style={{
      flexGrow: 2,
      backgroundColor: 'rgba(216, 216, 216, 0.95)',
      padding: 20,
      height: '100%',
      overflow: 'hidden',
    }}
  >
    <Switch>
      <Route exact path='/' component={() => <ImageDisplay />} />
      <PrivateRoute exact path="/gifts" component={() => <GiftList />} authenticated={props.authenticated} />
      <PrivateRoute exact path="/gifts/giftee" component={() => <GifteeList />} authenticated={props.authenticated} />
      <PrivateRoute exact path="/register" component={() => <Register />} authenticated={!props.authenticated} />
      <Route render={() => (<Redirect to='/' />)} />
    </Switch>
  </section>
);

const mapStateToProps = state => ({
  authenticated: !!(state.user.userId),
});

export default connect(mapStateToProps)(Content);