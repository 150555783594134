
const initialState = {
  user: {
    firstName: '',
    lastname: '',
    userId: null,
  },
  userGiftList: null,
  gifteeGiftList: null,
  giftee: null,
};

export default initialState;