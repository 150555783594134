import firebase from '../firebase';

const getGiftList = async (userId) => {
  const returnGiftList = await firebase.firestore()
    .collection('gifts')
    .doc(userId)
    .get()
    .then((snapshot) => (snapshot.exists ? ({ ...snapshot.data(), found: true, error: false }) : ({ found: false, error: false })))
    .catch((error) => ({ error: error.message }));
  return returnGiftList;
};

export default getGiftList;
