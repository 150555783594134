import initialState from './initialState';
import { USER_GIFT_LIST, RESET_STATE } from '../actions/actionTypes';

const userGiftListReducer = (state = initialState.userGiftList, action) => {
  if (action.type === USER_GIFT_LIST) {
    return action.payload;
  } else if (action.type === RESET_STATE) {
    return initialState.userGiftList;
  }
  return state;
};

export default userGiftListReducer;